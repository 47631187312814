.footer_container {
	background: #131415;
	padding: 50px;
	font-family: 'Euclid Circular A', sans-serif !important;
}

.footer {
	background-color: #131415;
	display: flex;
	justify-content: space-around;
	font-weight: 500;
	font-size: 0.9rem;
}

.bellow_footer {
	width: 75%;
	margin-top: -3em;
}

.footer .download {
	border: 1px solid #000;
	border-radius: 50px;
	font-weight: bold;
	text-align: center;
	display: inline-block;
	margin-top: 10px;
	padding: 5px 10px;
}

.coltitle {
	font-weight: bold;
	color: #000;
	text-transform: uppercase;
	margin: 10px 0;
	font-size: 1.3em;
}

.footer ul {
	padding: 0;
	margin: 0;
}
.footer ul li {
	list-style-type: none;
	margin-bottom: 15px;
	font-size: 1.3em;
}

.footer ul li a {
	font-weight: 500;
	color: #ffffff;
	text-decoration: none;
	cursor: pointer;
}

.footer ul li a:hover {
	text-decoration: underline;
}

.social-icon img {
	width: 2.5em;
	display: inline-block;
	margin: 0.25em;
}

.footer_section_title {
	color: #777a7e;
	font-size: 1.35em;
	margin-bottom: 1em;
	text-transform: uppercase;
}

.footer_section_title2 {
	color: #ffffff;
	font-size: 1.3em;
	font-weight: 500;
}

.footer_language_selector {
	border: none;
	background: transparent;
	color: white;
	margin-left: 1em;
	font-size: 1.35em;
	font-weight: 500;

	&:hover {
		cursor: pointer;
		border: 1px solid;
		border-radius: 5px;
	}

	&:lang(ar) {
		margin-left: 0;
		margin-right: 1em;
	}
}

.footer_left_container {
	display: flex;
	flex-direction: column;
	width: 75%;
	border-right: 1px solid #707070;
	justify-content: space-between;

	&:lang(ar) {
		border-left: 1px solid #707070;
		border-right: none;
	}
}

.footer_right_container {
	display: flex;
	flex-direction: column;
	width: 20%;
	padding: 0 !important;
}

.footer_button {
	background: #945cff 0 0 no-repeat padding-box;
	border: none;
	border-radius: 40px;
	color: #fff;
	font-size: 1.5em;
	margin: 2em auto 2em auto;
	padding: 0.6em;
	font-weight: bold;
	width: 100%;
	max-width: 15rem;
}

.footer_button:hover {
	background-color: rgba($color: #945cff, $alpha: 0.9);
}

.footer_social_container {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 0 !important;
	min-width: fit-content;
}

.social_icons_constainer {
	margin-left: 1em;

	&:lang(ar) {
		margin-left: 0;
		margin-right: 1em;
	}
}

.footer_bottom_row {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 0 !important;
}

.footer_image {
	margin-left: -2rem;
}

html[lang='ar'] .footer_image {
	margin-right: -2rem;
	margin-left: 0;
}

.footer_devices {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-top: 1em;
	color: #ffffff;
	margin-left: 3em;

	&:lang(ar) {
		margin-right: 2em;
		margin-left: 0;
	}
}

.footer_devices img {
	width: 2.5em;
	height: 2.5rem;
	margin-right: 1em;

	&:lang(ar) {
		margin-right: 0em;
		margin-left: 1em;
	}
}

.footer_devices a {
	text-decoration: none;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}
}

.devices_container {
	display: flex;
	flex-direction: column;
}

.footer_section_container {
	padding: 0 !important;
}

.arrow {
	transform: rotate(180deg);
}

.rottated_arrow {
	transform: rotate(0deg);
}

.logo {
	width: 11rem;
	height: 2.7rem;
}

@media (orientation: portrait) and (max-width: 900px) {
	.footer_container {
		padding: 20px;
	}
	.footer {
		flex-direction: column;
	}

	.footer_left_container {
		border: none;
		width: 100%;

		&:lang(ar) {
			border: none;
		}
	}

	.footer_right_container {
		margin: auto;
		padding: 0 !important;
	}

	.devices_container {
		display: flex;
		flex-direction: row;
	}

	.footer_devices {
		display: none;
	}

	.footer_section_title {
		width: 100%;
		padding: 0.5em 0;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 0 !important;
	}

	.footer_section_container {
		padding: 0 !important;
		border-bottom: 1px solid rgba(112, 112, 112, 0.4);
	}
	.footer ul {
		padding-bottom: 1em;
		margin: 0;
		transition: all 0.6s ease;
	}

	.footer_button {
		margin: 0 !important;
		max-width: 40vw;
		min-width: 40vw;
		font-size: 1.3em;
		padding: 0.8em;
	}

	.devices_container {
		width: 100%;
		align-items: center;
		margin: 3em 0;
		justify-content: space-around;
	}

	.footer_right_container {
		width: 100%;
		border-bottom: 1px solid rgba(112, 112, 112, 0.4);
	}

	.bellow_footer_row {
		flex-wrap: nowrap !important;
		justify-content: space-around !important;
	}

	.logo {
		width: 30vw;
		height: 3.5vh;
	}

	.footer_bottom_row {
		width: 30vw !important;
		margin: 0 !important;
		max-width: 30vw !important;
		padding: 0 !important;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	}

	.footer_social_container {
		width: 30vw !important;
		margin: 0 !important;
		max-width: 30vw !important;
		padding: 0 !important;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	}

	.footer_language_selector {
		margin-top: 1em;
		margin-left: 0;

		option {
			color: black;
		}
	}

	.social_icons_constainer {
		width: max-content;
	}

	.bellow_footer {
		width: 100%;
		margin-top: 2em !important;
	}

	.social-icon img {
		width: 2em;
	}

	.social_icons_constainer {
		margin-left: 0;
	}

	.bellow_footer .footer_section_title2 {
		font-size: 1.1em;
	}

	.footer_language_selector {
		margin-top: 0.7em;
		font-size: 1.1em;
	}
}
